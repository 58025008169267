import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { devices } from "../devices";

const Company = styled.a`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  @media ${devices.mobileL} {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: light;
  font-style: italic;
  margin-bottom: 16px;
  @media ${devices.mobileL} {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 28px;
  @media ${devices.mobileL} {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
  }
`;

export default function Experience({
  company,
  title,
  description,
  link,
  industry,
}) {
  return (
    <>
      <div>
        {industry ? (
          <Company className="highlighterBlue" href={link} target="_blank">
            {company}
          </Company>
        ) : (
          <Company className="highlighterYellow" href={link} target="_blank">
            {company}
          </Company>
        )}
      </div>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </>
  );
}

Experience.propTypes = {
  company: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  industry: PropTypes.bool,
};

Experience.defaultProps = {
  industry: true,
};
