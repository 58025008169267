import React from "react";
import styled from "styled-components";
import myPhoto from "./assets/myself.svg";
import calhacks from "./assets/calhacks.JPG";
import sep from "./assets/sep.png";
import Experience from "./components/Experience";
import Header from "./components/Header";
import { devices } from "./devices";

const HeaderWrapper = styled.div`
  height: 100px;
  display: flex;
  padding-right: 20px;
  justify-content: right;
  align-items: center;
`;

const Body = styled.div`
  margin-left: 10%;
  margin-right: 20%;
  margin-bottom: 80px;
  @media ${devices.mobileL} {
    margin-left: 8%;
    margin-right: 8%;
    margin-bottom: 40px;
  }
`;

const Introduction = styled.div`
  font-size: 36px;
  line-height: 55px;
  margin-bottom: 80px;
  @media ${devices.mobileL} {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 32px;
  }
`;

const MyPhoto = styled.img`
  max-width: 500px;
  margin-bottom: 24px;
  position: relative;
  left: -36px;
  @media ${devices.mobileL} {
    max-width: 100%;
    left: -7%;
    margin-bottom: 16px;
  }
`;

const SectionTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
  line-height: 74px;
  margin-bottom: 24px;
  @media ${devices.mobileL} {
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 12px;
  }
`;

const SectionSplitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobileL} {
    flex-direction: column;
  }
`;

const SectionSplitLeft = styled.div`
  width: 70%;
  padding-right: 5%;
  @media ${devices.mobileL} {
    width: 100%;
    padding-right: 0%;
    margin-bottom: 32px;
  }
`;

const SectionSplitRight = styled.div`
  position: relative;
  height: 300px;
  top: -100px;
  right: -50px;
  @media ${devices.mobileL} {
    top: 0px;
    right: 0px;
    text-align: center;
    position: static;
    height: auto;
    margin-bottom: 80px;
  }
`;

const Caption = styled.div`
  font-size: 24px;
  font-weight: light;
  font-style: italic;
  @media ${devices.mobileL} {
    font-size: 14px;
  }
`;

const SEPPhoto = styled.div`
  max-width: 500px;
  margin-bottom: 75px;
  @media ${devices.mobileL} {
    margin-bottom: 10px;
  }
`;

const CalHacksPhoto = styled.div`
  max-width: 300px;
  position: relative;
  right: -200px;
  @media ${devices.mobileL} {
    right: 0px;
    max-width: 100%;
  }
`;

const SectionText = styled.div`
  font-size: 36px;
  line-height: 55px;
  margin-bottom: 80px;
  @media ${devices.mobileL} {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 0px;
  }
`;

const Experiences = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  @media ${devices.mobileL} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

const ExperienceWrapper = styled.div`
  width: 45%;
  @media ${devices.mobileL} {
    width: 100%;
  }
`;

function App() {
  return (
    <div>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <Body>
        <div>
          <MyPhoto
            src={myPhoto}
            alt="David Wei standing on a boat in the Bay"
          />
          <Introduction>
            I'm David Wei, a computer science major at UC Berkeley, where I hope
            to tackle society's most pressing issues through collaboration and
            innovation.
          </Introduction>
        </div>
        <SectionTitle>current ~</SectionTitle>
        <SectionSplitWrapper>
          <SectionSplitLeft>
            <SectionText>
              I'm currently working as a software engineering intern at{" "}
              <a
                className="highlighterBlue"
                href="https://www.google.com"
                target="_blank"
                rel="noreferrer"
              >
                Google
              </a>
              , working on{" "}
              <a
                className="highlighterBlue"
                href="https://research.google/pubs/pub43438/"
                target="_blank"
                rel="noreferrer"
              >
                Borg SRE!
              </a>{" "}
              I am also learning about CRDTs and distributed systems while
              conducting research in UC Berkeley's{" "}
              <a
                className="highlighterYellow"
                href="https://rise.cs.berkeley.edu/"
                target="_blank"
                rel="noreferrer"
              >
                RISE Lab.
              </a>
            </SectionText>
          </SectionSplitLeft>
          <SectionSplitRight>
            <SEPPhoto>
              <img
                src={sep}
                alt="sep on a boat! FA2021"
                style={{ width: "100%" }}
              />
              <Caption>sep on a boat! FA2021</Caption>
            </SEPPhoto>
            <CalHacksPhoto>
              <img
                src={calhacks}
                alt="cal hacks photoshoot, FA2021"
                style={{ width: "100%" }}
              />
              <Caption>cal hacks photoshoot, FA2021</Caption>
            </CalHacksPhoto>
          </SectionSplitRight>
        </SectionSplitWrapper>
        <SectionTitle>previous ~</SectionTitle>
        <div>
          <Experiences>
            <ExperienceWrapper>
              <Experience
                company="Sigma Eta Pi"
                title="Co-President"
                description="Led and created opportunities for interdisciplinary, professional learning at UC Berkeley's premier, co-ed entrepreneurship fraternity."
                industry={false}
                link="https://www.sigmaetapi.org/chapters"
              />
            </ExperienceWrapper>
            <ExperienceWrapper>
              <Experience
                company="Capital One"
                title="Software Engineer Intern"
                description="Developed a tool which standardizes deployment of data analysis and machine learning models."
                link="https://www.capitalone.com"
              />
            </ExperienceWrapper>
          </Experiences>
          <Experiences>
            <ExperienceWrapper>
              <Experience
                company="Parrot"
                title="Software Engineer Intern"
                description="Built a webapp which allowed businesses to build relationships with customers through direct-text-marketing."
                link="https://getparrot.com"
              />
            </ExperienceWrapper>
            <ExperienceWrapper>
              <Experience
                company="Cal Hacks"
                title="Lead Finance Director"
                description="Organized the world's largest intercollegiate hackathon and worked to break down the barriers of entry into 'hacker' and computer science culture."
                industry={false}
                link="https://calhacks.io"
              />
            </ExperienceWrapper>
          </Experiences>
          <Experiences>
            <ExperienceWrapper>
              <Experience
                company="CS61BL"
                title="Academic Intern"
                description="Taught and mentored a group of 30 students in topics for data structures and algorithms."
                link="https://cs61bl.org/su20/"
              />
            </ExperienceWrapper>
          </Experiences>
          <Experiences>
            <ExperienceWrapper>
              <Experience
                company="Public Editor"
                title="Software Engineer Intern"
                description="Developed a web tool designed to combat misinformation."
                link="https://www.publiceditor.io"
              />
            </ExperienceWrapper>
            {/* SPACE FOR MORE HERE */}
          </Experiences>
        </div>
      </Body>
      <div style={{ float: "right", "padding-bottom": "10px" }}>
        last updated: July 9, 2022
      </div>
    </div>
  );
}

export default App;
