import React from "react";
import styled from "styled-components";

const Page = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

export default function Bonus() {
  return (
    <Page>
      Thank you to{" _"}
      <a
        className="highlighterYellow"
        href="https://www.lucysong.me/"
        target="_blank"
        rel="noreferrer"
      >
        Lucy Song
      </a>
      {"_ "}
      for the help with design :)
    </Page>
  );
}
