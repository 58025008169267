import React from "react";
import styled from "styled-components";
import resume from "../assets/resume.pdf";

const StyledLink = styled.a`
  margin-left: 30px;
  text-decoration: none;
  color: black;
`;

export default function Header() {
  return (
    <>
      <StyledLink
        className="highlighterBlue"
        href="https://www.linkedin.com/in/davidmwei"
        target="_blank"
        rel="noreferrer"
      >
        LinkedIn
      </StyledLink>
      <StyledLink
        className="highlighterBlue"
        href="https://github.com/davidmwei"
        target="_blank"
        rel="noreferrer"
      >
        GitHub
      </StyledLink>
      <StyledLink className="highlighterBlue" href={resume} target="_blank">
        Resume
      </StyledLink>
    </>
  );
}
